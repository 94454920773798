* {
  box-sizing: border-box;
}

/* Demo only */
body {
  background: #f1f1f1;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.selected-item {
  text-align: center;
}

:root {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

.controls-container {
  display: flex;
  height: 64x;
  padding: 18px;
  margin-bottom: 18px;
  align-self: center;
}

@media (max-width: 500px) {
  .controls-container {
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%, -15%) scale(0.9);      
    z-index: 99;
  }
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: #d6e4ff;
  border-radius: 30px;
  max-width: 420px;
  padding: 12px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 100px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 1px;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: "";
  background: rgb(20,21,22);
  border-radius: 25px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

.indexedStep {
  color: white;
  width: 50px;
  height: 50px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}